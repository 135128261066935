import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";
import { Data } from "./Data";

export default async function GetAllStakes(id,ind) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      Data.providerLink
    );
    const signer = provider.getSigner(id);

    const contractInstance = new ethers.Contract(
      ContractDetails.controller,
      ContractDetails.controllerABI,
      signer
    );

    const tradeData = await contractInstance.getAllStakes(id,ind);
    Data.isDebug && console.log("tradeData data:", tradeData);
    return tradeData;

  } catch (error) {
    console.error("Error fetching tradeData data:", error);
    throw error;
  }
}
