import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import "./MyChart.css";
import { Data } from "../../Common/Data";
const MyChart = (props) => {
  // Data.isDebug && console.log("data", props)
  const root = document.documentElement;
  const rootStyles = getComputedStyle(root);
  const rootColor = rootStyles.getPropertyValue("--colorPrimary");
  const chartMinedValue = props?.stake - props?.mined;

  const data = [
    {
      title: "LICENSE",
      value: parseFloat(props?.mined ?? 0.00000000001),
      color: rootColor,
    },
    {
      title: "LENDING LIMIT",
      value: parseFloat(chartMinedValue ?? 0.00000000001),
      color: "grey",
    },
  ];

  return (
    <div className="capping">
      {/* <div className="cappingGraphDiv">
        <div style={{ height: "120px" }}>
          {/* <PieChart
            animate={true}
            animationDuration={2000}
            data={data}
            lineWidth={30}
            paddingAngle={2}
            label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
            labelStyle={{
              fill: "#fff",
              fontSize: "5px",
              fontFamily: "sans-serif",
              fontSize: "8px",
              fontWeight: "bold",
              borderRadius: "50%",
              background: "red !important",
            }}
          /> */}
      {/* </div> */}
      {/* <div className="currentLicense ">
        <p id="promo">{props?.packtype}</p>
        <h5 className="dashboardCardHeading m-0">Current License</h5>
      </div> */}

      <div className="cappingDetails cappingDetailsDashboard">
        <div>
          <div>
            <h1>{props.stake} {Data?.coinName}</h1>
            <p>LICENSE</p>
          </div>
          <div>
            <h1>{props.mined} {Data?.coinName}</h1>
            <p>LENDING LIMIT</p>
          </div>
        </div>
        <div>
          <div>
            <h1>{props?.claimed} {Data?.coinName}</h1>
            <p>CAPPING</p>
          </div>
          <div>
            <h1>{props.repurchase} </h1>
            <p>TODAY REMAINING LIMIT</p>
          </div>
        </div>
      </div>
    </div >
  );
};

export default MyChart;
// animationDuration: number;
// animationEasing: string;
// center: [number, number];
// data: Data < BaseDataEntry >;
// labelPosition: number;
// lengthAngle: number;
// lineWidth: number;
// paddingAngle: number;
// radius: number;
// startAngle: number;
// viewBoxSize: [number, number];
