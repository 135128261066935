import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "./Slot.css";
import { Row, Col } from "react-bootstrap";
import { FaTelegramPlane } from "react-icons/fa";
import { ApiPaths } from "../../Config";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import InfoPage from "../../Components/InfoPage/InfoPage";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Data, toastInfo, toastSuccess } from "../../Common/Data";
import MyChart from "../../Components/MyChart/MyChart";
import getBalance from "../../Common/GetBalance";
import ContractDetails, { getContract } from "../../Contracts/ContractDetails";
import dollar_11266963 from "../../Images/dollar_11266963.png";
import arrow_5179955 from "../../Images/arrow_5179955.png";
import adjust_8805063 from "../../Images/adjust_8805063.png";
import USDT from "./../../Images/USDT.png";
import QUICK from "../../Images/QUICK.png";
import polygonmaticlogo from "../../Images/polygon-matic-logo.svg";
import AAVE from "../../Images/aave-aave-logo.png";
import { ethers } from "ethers";
import { IoMdSwap } from "react-icons/io";
import GetChainId from "../../Common/GetChainId";
import ConnectButton from "../../Components/ConnectButton";
import { useSelector } from "react-redux";
import GetTradeCaps from "../../Common/GetTradeCaps";
const Dashboard = () => {
  const { BigInt } = window;
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [incomeInfo, setIncomeInfo] = useState(false);
  const [incomeHeading, setIncomeHeading] = useState(false);
  const [infoData, setInfoData] = useState();
  const [activeTrade, setActiveTrade] = useState({});
  const [activeDate, setActiveDate] = useState("");
  const [tradeAmount, setTradeAmount] = useState();
  const [margin, setMargin] = useState(0);
  const [status, setStatus] = useState("");
  const [stakes, setStakes] = useState([]);
  const [paybleAmount, setPaybleAmount] = useState(0);
  const [tradeCapState, setTradeCapState] = useState([]);
  const [autoPilot, setautoPilot] = useState(false);
  const fetchStakes = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(ContractDetails.controller, ContractDetails.controllerABI, signer);
    if (contract) {
      const userAddress = await signer.getAddress();
      console.log("90900909090909090",userAddress)
      const stakesArray = [];
      let index = 0;
      const maxStakes = 100; // Set a reasonable maximum number of stakes
      let payble = 0;
      try {
        while (index < maxStakes) {
          try {
            const stake = await contract.getAllStakes(userAddress, index);
            const formattedStake = {
              stakedUSDT: stake[0].toNumber(),
              landing_limit: stake[1].toNumber(),
              daily_limit: stake[2].toNumber(),
              today_used_limit: stake[3].toNumber(),
              lastTradeDay: stake[4].toNumber(),
              autoPilot: stake[5],
              claimCount: stake[6].toNumber(),
              autoPilotOnTime: new Date(stake[7].toNumber() * 1000).toLocaleString(),
              lastClaimed: new Date(stake[8].toNumber() * 1000).toLocaleString()
            };
            console.log("1222222222222212122121212121",stake)
            payble += (stake[0].toNumber() / 100) * 0.201 * 1e18;
            stakesArray.push(formattedStake);
            index++;
          } catch (error) {
            console.log(error)
            break; // Exit the loop if an error occurs (e.g., out-of-bounds)
          }
        }
        if (stakesArray.length > 0) {
          setStakes(stakesArray);
          setautoPilot(stakesArray[stakesArray.length-1].autoPilot)
          setPaybleAmount(payble)
          console.log('payble amount',payble)
          console.log("Fetched all stakes successfully:", stakesArray);
        } else {
          console.error("No stakes found for the user.");
        }
      } catch (error) {
        console.error("Error fetching stakes:", error);
      }
    }
  };

  useEffect(() => {
    fetchStakes()

  }, [window.ethereum])
  const [claimRemainingTime, setClaimRemainingTime] = useState({ hours: "00", minutes: "00", seconds: "00" })
  function calculateRemainingTimeFromAutoPilotTime(autoPilotOnTime, h) {
    if (autoPilotOnTime?.length > 0) {
      // Split the string to extract date and time parts
      const [datePart, timePart] = autoPilotOnTime?.split(', ');
      const [day, month, year] = datePart?.split('/');
      const [time, ampm] = timePart?.split(' ');

      // Convert time to 24-hour format
      const [hours, minutes, seconds] = time?.split(':').map(Number);
      const hour = hours === 12 && ampm === 'AM' ? 0 : hours + (ampm === 'PM' ? 12 : 0);

      // Create the date object
      const autoPilotTime = new Date(year, month - 1, day, hour, minutes, seconds);
      const currentTime = new Date();

      // Calculate the difference in milliseconds
      const difference = autoPilotTime.getTime() + h * 60 * 60 * 1000 - currentTime.getTime();
      if (difference < 0) {
        return;
      }
      // Convert milliseconds to hours, minutes, and seconds
      const hoursDiff = Math.floor(difference / (1000 * 60 * 60));
      const minutesDiff = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const secondsDiff = Math.floor((difference % (1000 * 60)) / 1000);
      setClaimRemainingTime({ hours: hoursDiff, minutes: minutesDiff, seconds: secondsDiff })
      return { hours: hoursDiff, minutes: minutesDiff, seconds: secondsDiff };
    }
  }
  useEffect(() => {
    const { time, hour } = stakes[0]?.autoPilot ? { time: stakes[0]?.autoPilotOnTime, hour: 3 } : { time: stakes[0]?.lastClaimed, hour: 21 }
    setInterval(() => {
      if (claimRemainingTime?.seconds >= 0) {
        // console.log('====================', claimRemainingTime?.seconds)
        calculateRemainingTimeFromAutoPilotTime(time, hour)
      }
      // console.log(`Remaining time until claim: ${remainingTime.hours} hours, ${remainingTime.minutes} minutes, ${remainingTime.seconds} seconds`);
    }, 1000);

  }, [stakes])
  const enableAutoPilot = async () => {
    setLoading(true);
    const contract = await getContract();
    if (contract) {
      try {
        const totalStaked = await stakes.reduce((total, stake) => total + stake.stakedUSDT, 0);
        console.log(paybleAmount)
        const tx = await contract.autoPilotOn({ value: BigInt(paybleAmount) }); // Adjust value if needed
        await tx.wait();
        setStatus("AutoPilot enabled successfully!");
        toastInfo("AutoPilot enabled successfully!")
        setLoading(false);
      } catch (error) {
        toastInfo(error.reason)
        console.error("Error enabling AutoPilot:", error);
        setStatus("Failed to enable AutoPilot");
        setLoading(false);
      }
    }
  };
  const claimRewards = async () => {
    const contract = await getContract();
    if (contract) {
      try {
        const tx = await contract.claim();
        await tx.wait();
        toastInfo("Claim successful!");
      } catch (error) {
        console.error("Error claiming rewards:", error);
        toastInfo(error.reason);
      }
    }
  };
  const acc = useSelector((state) => state.account.value);
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };
  const [wbtc, setwbtc] = useState("");
  let x = 0;

  const [pendingTime, setPendingTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const { hours, minutes, seconds } = calculateRemainingTime(activeDate);
      setPendingTime(calculateRemainingTime({ hours, minutes, seconds }));
      // console.log("------------------", pendingTime);
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  function calculateRemainingTime() {
    const tempactiveDate = new Date(dashboardData?.profile?.[0]?.active_date);
    const startTime = new Date(tempactiveDate);
    const endTime = new Date(startTime.getTime() + 72 * 60 * 60 * 1000); // Add 72 hours in milliseconds

    const currentTime = new Date();
    const difference = endTime.getTime() - currentTime.getTime();

    // Calculate remaining time
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    // console.log("=-==-=-=-=-=-=", Number(hours), minutes, seconds);
    return { hours, minutes, seconds };
  }

  function generateRandom() {
    const newRandomNumber = Math.floor(Math.random() * 11) + 25;
    setMargin(newRandomNumber);
    const interval = setInterval(() => {
      // Generate a random number between 25 and 35
      const newRandomNumber = Math.floor(Math.random() * 11) + 25;
      setMargin(newRandomNumber);
    }, 15000);
    return () => clearInterval(interval);
  }

  useEffect(() => {
    const newRandomNumber = Math.floor(Math.random() * 11) + 25;
    setMargin(newRandomNumber);
  }, [activeTrade]);

  useEffect(() => {
    if (x === 0) {
      generateRandom();
      setActiveTrade({
        position: "1",
        tokenA: "USDC.e",
        tokenB: "DAI",
      });
      checkData();
      x = 1;
    }
  }, []);

  const fetchTradeCaps = async (address) => {
    Data.isDebug && console.log("address", address);
    const tradeCapData = await GetTradeCaps(address);
    setTradeCapState(tradeCapData);
  }

  const toastFailed = (msg) => toast.error(msg);
  function IncomeInfoState(newstate) {
    setIncomeInfo(newstate);
  }
  async function getBalanceFunction(address) {
    const balance = await getBalance(address);
    // setWalletBalance(balance);
  }
  // async function fetchContractData(address) {
  //   try {
  //     getBalanceFunction(address);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  function checkData() {
    let jsondata = localStorage.getItem("dashboardData");
    let contractlocal = localStorage.getItem("contractDetails");
    let contractDetails = JSON.parse(contractlocal);
    const data = JSON.parse(jsondata);
    // Data.isDebug && console.log("data", data);
    // Data.isDebug && console.log("contractDetails", contractDetails);
    if (data) {
      setDashboardData(data);
      fetchTradeCaps(data?.profile?.[0]?.wallet_address);
      // fetchContractData(data?.profile?.[0]?.wallet_address);
      FetchData();
    } else {
      FetchData(true);
    }
  }
  function FetchData(checkload) {
    if (checkload) {
      setLoading(true);
    }
    let userId = localStorage.getItem("userId");
    // Data.isDebug && console.log("user id", userId);
    axios({
      method: "post",
      url: ApiPaths.dashboard,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // Data.isDebug && console.log("dashboarddata ", response);
        setDashboardData(response?.data);
        // Data.isDebug &&
        //   console.log(
        //     "response?.data?.profile?.[0]?.wallet_address",
        //     response?.data?.profile?.[0]?.wallet_address
        //   );

        let add = response?.data?.profile?.[0]?.wallet_address;
        // fetchContractData(add);
        localStorage.setItem("dashboardData", JSON.stringify(response?.data));
        localStorage.setItem(
          "walletAddress",
          response?.data?.profile?.[0]?.wallet_address
        );
        fetchTradeCaps(response?.data?.profile?.[0]?.wallet_address);
        setLoading(false);
      })
      .catch(function (response) {
        // Data.isDebug && console.log("errorettttttttttttttttttt", response);
        setLoading(false);
      });
  }

  async function TradeFunc() {
    if (tradeAmount >= 100 && tradeAmount <= 1500) {
      try {
        setLoading(true);
        let { ethereum } = window;
        if (ethereum) {
          let chain = await GetChainId();
          if (chain) {
            let provider = new ethers.providers.Web3Provider(ethereum);
            let signer = provider.getSigner();
            let contractInstance = new ethers.Contract(
              ContractDetails.tradingContract,
              ContractDetails.tradingABI,
              signer
            );
            let bal = BigInt(tradeAmount * 1e6);
            // console.log("balance", bal);
            let inc = await contractInstance.requestFlashLoan(bal);
            await inc.wait();
            // console.log("inc", inc);
            toastSuccess("Transaction Successfull");
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          toastFailed("Please check your wallet");
        }
      } catch (error) {
        // Data.isDebug && console.log("TradeFunc Error", error);

        toastFailed(error?.error?.data?.message);
        setLoading(false);
      }
    } else {
      toastFailed("Amount should be between $100 to $1500");
    }
  }

  async function NotMargin() {
    setLoading(true);
    await delay(500);
    setLoading(false);
    toastInfo("Low Margin");
  }

  return (
    <>
      {loading ? <Loader /> : null}
      {incomeInfo ? (
        <InfoPage
          updateState={IncomeInfoState}
          heading={incomeHeading}
          data={infoData}
        />
      ) : null}
      <section className="dashboard">
        {/* <div
          id="dashboardRefLink"
          style={{ border: "1px solid #252525" }}
          className="headerLinkDiv"
          onClick={() => CopyFromtag("profileLink")}
        >
          <div className="linktext">
            <p>your referral link</p>
            <h2 id="profileLink">{`${window.location.origin}/register?ref=${dashboardData?.profile?.[0]?.username}`}</h2>
          </div>
          <i>
            <FiCopy />
          </i>
          <ReferralComponent
            link={`${window.location.origin}/register?ref=${dashboardData?.profile?.[0]?.username}`}
          />
        </div> */}
        {acc?.length > 0 ? (
          <p id="walletConnected">Connected : {acc}</p>
        ) : (
          <div>
            <p id="walletDisconnected">Wallet not connected</p>
            <ConnectButton />
          </div>
        )}
        <section className="cappingSection mt-4">
          <div className="viewCappingDiv ">
            <h1 className="textHeading">LICENSE</h1>
            <Link to="staking_details" className="mb-2">
              <p>View All</p>
            </Link>
          </div>
          {tradeCapState != null && tradeCapState ? (
            <MyChart
              className="dashboardChart"
              stake={parseFloat(tradeCapState?.totalStakedUSDT)}
              mined={parseFloat(tradeCapState?.totalLandingLimit)}
              claimed={parseFloat(tradeCapState?.totalStakedUSDT) * 5}
              repurchase={parseFloat(tradeCapState?.remainingDailyLimit)}
              packtype={tradeCapState?.rank}
            />
          ) : (
            ""
          )}
        </section>
        <h1 className="textHeadingWithMargin">Trade</h1>
        <div class="marqueeText">
          {/* <marquee behavior="" direction="left"> */}
          <div class="currencyMarquee">
            <div
              class="currencyDiv"
              id={activeTrade.position == 1 ? "activeTrade" : ""}
              onClick={() =>
                setActiveTrade({
                  position: "1",
                  tokenA: "USDC.e",
                  tokenB: "DAI",
                })
              }
            >
              <div class="currencyItemDiv">
                <div class="currencyItem">
                  <img src={dollar_11266963} width="50px" />
                  <p>USDC.e</p>
                </div>
                <img src={arrow_5179955} width="30px" />
                <div class="currencyItem">
                  <img src={adjust_8805063} width="50px" />
                  <p>DAI</p>
                </div>
              </div>
              {/* <div class="currencyDivPrice">
                <p></p>
                <h5>$ {`${daiPrice}`}</h5>
              </div> */}
            </div>
            <div
              class="currencyDiv"
              id={activeTrade.position == 2 ? "activeTrade" : ""}
              onClick={() =>
                setActiveTrade({
                  position: "2",
                  tokenA: "USDC.e",
                  tokenB: "MATIC",
                })
              }
            >
              <div class="currencyItemDiv">
                <div class="currencyItem">
                  <img src={dollar_11266963} width="50px" />
                  <p>USDC.e</p>
                </div>
                <img src={arrow_5179955} width="30px" />
                <div class="currencyItem">
                  <img src={polygonmaticlogo} width="50px" />
                  <p>MATIC</p>
                </div>
              </div>
              {/* <div class="currencyDivPrice">

                <h5>$ {maticPrice}</h5>
              </div> */}
            </div>
            <div
              class="currencyDiv"
              id={activeTrade.position == 3 ? "activeTrade" : ""}
              onClick={() =>
                setActiveTrade({
                  position: "3",
                  tokenA: "USDC.e",
                  tokenB: "AAVE",
                })
              }
            >
              <div class="currencyItemDiv">
                <div class="currencyItem">
                  <img src={USDT} width="50px" />
                  <p>USDT</p>
                </div>
                <img src={arrow_5179955} width="30px" />
                <div class="currencyItem">
                  <img src={AAVE} width="50px" />
                  <p>AAVE</p>
                </div>
              </div>
              {/* <div class="currencyDivPrice">

                <h5>$ {wbtc}</h5>
              </div> */}
            </div>
            <div
              class="currencyDiv"
              id={activeTrade.position == 4 ? "activeTrade" : ""}
              onClick={() =>
                setActiveTrade({
                  position: "4",
                  tokenA: "DAI",
                  tokenB: "MATIC",
                })
              }
            >
              <div class="currencyItemDiv">
                <div class="currencyItem">
                  <img src={adjust_8805063} width="50px" />
                  <p>DAI</p>
                </div>
                <img src={polygonmaticlogo} width="30px" />
                <div class="currencyItem">
                  <img src={QUICK} width="50px" />
                  <p>MATIC</p>
                </div>
              </div>
              {/* <div class="currencyDivPrice">

                <h5>$ {quickprice}</h5>
              </div> */}
            </div>
          </div>

          {/* </marquee> */}
        </div>
        <Row>
          <Col lg="8">
            <Row style={{ height: "100%" }}>
              <Col md="6" className="mb">
                <div className="dashboardMainAccountCard d-flex flex-column justify-content-between">
                  <h5 className="dashboardCardHeading">Auto Pilot</h5>
                  <div>

                    <h5
                      style={{
                        color: "var(--colorPrimary)",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {`${claimRemainingTime?.hours} : ${claimRemainingTime?.minutes} : ${claimRemainingTime?.seconds}`}
                    </h5>
                    <h5
                      style={{
                        color: "var(--colorPrimary)",
                        fontSize: "10px",
                        fontWeight: "500",
                        textTransform: "uppercase"
                      }}
                    >
                      {`Remaining time until ${!stakes[stakes.length-1]?.autoPilot ? "start" : "claim"}`}
                    </h5>
                  </div>
                  {!stakes[stakes.length-1]?.autoPilot ? <button className="btnPrimary m-0" onClick={enableAutoPilot}>
                    Start
                  </button> :
                    <button className="btnPrimary m-0" onClick={claimRewards}>
                      Claim
                    </button>}
                  {/* <h5 className="dashboardCardHeading">User Account</h5> */}
                  {/* <div className="metaDiv">
                    <div className="dashboardProfile">
                      <p>Username:</p>
                      <p>{dashboardData?.profile?.[0]?.username}</p>
                    </div>
                    <div className="dashboardProfile">
                      <p>Name:</p>
                      <p>{dashboardData?.profile?.[0]?.name}</p>
                    </div>
                    <div className="dashboardProfile">
                      <p>Joining Date:</p>
                      <p>{dashboardData?.profile?.[0]?.added_on}</p>
                    </div>
                    <div className="dashboardProfile">
                      <p>Remaining Time:</p>
                      <p>{`${pendingTime?.hours} hours, ${pendingTime?.minutes} minutes, ${pendingTime?.seconds} seconds`}</p>
                    </div>
                  </div> */}
                </div>
              </Col>
              <Col md="6" className="mb" id="tradeDivOrder">
                <div className="dashboardRankCard">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    {/* <h5 className="dashboardCardHeading m-0">Trading wallet</h5> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "10px",
                      }}
                    >
                      <h5 className="dashboardCardHeading m-0">
                        {activeTrade.position == 1
                          ? activeTrade.tokenA
                          : activeTrade.position == 2
                            ? activeTrade.tokenA
                            : activeTrade.position == 3
                              ? activeTrade.tokenA
                              : activeTrade.position == 4
                                ? activeTrade.tokenA
                                : "...."}
                      </h5>
                      <i>
                        <IoMdSwap />
                      </i>
                      <h5 className="dashboardCardHeading m-0">
                        {activeTrade.position == 1
                          ? activeTrade.tokenB
                          : activeTrade.position == 2
                            ? activeTrade.tokenB
                            : activeTrade.position == 3
                              ? activeTrade.tokenB
                              : activeTrade.position == 4
                                ? activeTrade.tokenB
                                : "...."}
                      </h5>
                    </div>
                  </div>
                  {/* <p id="promo">{`$ ${dashboardData?.wallets?.trading_wallet}`}</p> */}
                  {/* <div id="promo">
                    <p>Min Profit</p>
                    <p>
                      ${" "}
                      {activeTrade.position == 1
                        ? daiPrice
                        : activeTrade.position == 2
                          ? maticPrice
                          : activeTrade.position == 3
                            ? wbtc
                            : activeTrade.position == 4
                              ? quickprice
                              : "0"}
                    </p>
                  </div> */}

                  <input
                    type="number"
                    min={300}
                    max={1500}
                    id="dashboardTradAmountInput"
                    placeholder="Amount"
                    value={tradeAmount}
                    onChange={(e) => setTradeAmount(e.target.value)}
                  />

                  <div className="aboutDetails">
                    <p>Margin</p>
                    <h5
                      style={{
                        color: "green",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      +0.{margin}%
                      {/* {activeTrade?.position == 3 ? `+0.${margin}%` : "+0.0%"} */}
                    </h5>
                  </div>
                  {acc?.length > 0 ? (
                    <button
                      className="btnPrimary"
                      style={{ margin: "10px 0 0 0 !important" }}
                      onClick={() =>
                        activeTrade?.position == 3 ? TradeFunc() : NotMargin()
                      }
                    >
                      Trade
                    </button>
                  ) : (
                    <ConnectButton />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            lg="4"
            className="gap-2 d-flex flex-column justify-content-between"
          >
            <Row>
              <div className="dashboardIncomeCard">
                <div>
                  <h5 className="dashboardCardHeading">Daily Limit</h5>
                  <h1>$ {dashboardData?.license * 10}</h1>
                </div>
              </div>
              <div className="dashboardIncomeCard">
                <div>
                  <h5 className="dashboardCardHeading">Total Limit</h5>
                  <h1>$ {dashboardData?.limit}</h1>
                </div>
              </div>
            </Row>
          </Col>
        </Row >

        <h1 className="textHeadingWithMargin">Telegram</h1>
        <div className="telegramBotDiv">
          <Row className="align-items-center">
            <Col md="6">
              <div id="telegramBotDivText">
                <i>
                  <FaTelegramPlane />
                </i>
                <div>
                  <h5>Arbits Notifier</h5>
                  <p>New partners and transactions notifications</p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <button
                onClick={() =>
                  window.open(dashboardData?.telegram_link, "_blank")
                }
                className="btnPrimary"
              >
                Connect
              </button>
            </Col>
          </Row>
        </div>
      </section >
    </>
  );
};

export default Dashboard;
