import React, { useEffect, useState } from "react";
import "./AllCapping.css";
import Loader from "../../Components/Loader/Loader";
import MyChart from "../../Components/MyChart/MyChart";
import GetAllStakes from "../../Common/GetAllStakes";
const AllCapping = () => {
  const [loading, setLoading] = useState();
  const [allStakingData, setAllStakingData] = useState();
  const walletAddress = localStorage.getItem("walletAddress");
  var x = 0;
  const MaxStake = 100;
  useEffect(() => {
    if (x === 0) {
      FetchData();
      x = 1;
    }
  }, []);

  async function FetchData() {
    let stakeCount = 0;
    let tempArray = [];
    setLoading(true);
    while (stakeCount < MaxStake) {

      try {
        const tempData = await GetAllStakes(walletAddress, stakeCount);
        console.log("tempData", tempData);
        tempArray.push(
          {
            "license": parseFloat(tempData?.stakedUSDT),
            "capping": parseFloat(tempData?.stakedUSDT) * 5,
            "lendingLimit": parseFloat(tempData?.landing_limit),
            "remainingLimit": parseFloat(tempData?.daily_limit) - parseFloat(tempData?.today_used_limit)
          }
        )
        stakeCount++;
      } catch (e) {
        setLoading(false);
        setAllStakingData(tempArray);
        console.log("e22222222222222222222222222222222", e)
        break
      }
    }


    // setAllStakingData(data?.pkg_info);
  }
  return (
    <>
      {
        loading && <Loader />
      }
      <section className="dashboard">
        {allStakingData && allStakingData?.map((stakeData) => (
          <MyChart
            className="dashboardChart"
            stake={stakeData?.license}
            mined={stakeData?.lendingLimit}
            claimed={stakeData?.capping}
            repurchase={stakeData?.remainingLimit}
          />
        ))}
        {/* {allStakingData?.map((x, i) => {
          return <MyChart
          className="dashboardChart"
          stake={x?.order_amount}
          mined={x?.lending_limit}
          claimed={x?.order_capping}
          repurchase={x?.frequency}
        />
     
        })} */}
      </section>
    </>
  );
};

export default AllCapping;
