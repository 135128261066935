import React, { useEffect, useState } from "react";
import "./Plans.css";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { ApiPaths } from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import { Data } from "../../Common/Data";
import UpgradeCard from "./../../Components/UpgradeCard/UpgradeCard";
import Loader from "../../Components/Loader/Loader";
const Plans = () => {
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [aiUserId, setAiUserId] = useState("");
  const [aiUserIdError, setAiUserIdError] = useState("");
  const [aiPackage, setAiPackage] = useState("1");
  const [aiPackageError, setAiPackageError] = useState("");
  const [subsAmount, setSubsAmount] = useState("");
  const [subsAmountError, setSubsAmountError] = useState("");
  const [subsWallets, setSubsWallets] = useState("1");
  const [subsPackage, setSubsPackage] = useState("1");
  const [subsUserId, setSubsUserId] = useState("");
  const [subsWalletsError, setSubsWalletsError] = useState("");
  const [subsPackageError, setSubsPackageError] = useState("");
  const [subsUserIdError, setSubsUserIdError] = useState("");
  const [planData, setplanData] = useState([]);
  const [sponsorLoading, setSponsorLoading] = useState(false);
  const [checkSponsorExist, setCheckSponsorExist] = useState([]);
  useEffect(() => {
    checkData();
    FetchPackages();
    FetchPlanData();
  }, []);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);
  const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  }
  function checkData() {
    let jsondata = localStorage.getItem("dashboardData");
    const data = JSON.parse(jsondata);
    Data.isDebug && console.log("data", data)
    if (data) {
      setDashboardData(data);
      setAiUserId(data?.profile?.[0]?.username);
      onUserStoppedTyping(data?.profile?.[0]?.username);
      setSubsUserId(data?.profile?.[0]?.username);
      FetchData();
    } else {
      FetchData();
    }
  }
  function FetchPackages() {
    // setLoading(true);
    let userId = localStorage.getItem("userId");
    axios({
      method: "post",
      url: ApiPaths.packages,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        console.log("packages", response);
        setPackagesData(response?.data);
        localStorage.setItem("packages", JSON.stringify(response?.data));
        setLoading(false);
      })
      .catch(function (response) {
        // console.log(response);
        setLoading(false);
      });
  }

  function FetchData() {
    // setLoading(true);
    let userId = localStorage.getItem("userId");
    axios({
      method: "post",
      url: ApiPaths.dashboard,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // console.log("datatatat", response);
        setDashboardData(response?.data);
        setAiUserId(response?.data?.profile?.[0]?.username);
        onUserStoppedTyping(response?.data?.profile?.[0]?.username);
        setSubsUserId(response?.data?.profile?.[0]?.username);
        localStorage.setItem("dashboardData", JSON.stringify(response?.data));
        setLoading(false);
      })
      .catch(function (response) {
        // console.log(response);
        setLoading(false);
      });
  }
  function FetchPlanData() {
    setLoading(true);
    let token = localStorage.getItem("token");
    axios({
      method: "get",
      url: ApiPaths.getPlans,
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("plan data", response);
        console.log('plan==', response.data)
        setplanData(response?.data);
        setLoading(false);
      })
      .catch(function (response) {
        // console.log(response);
        setLoading(false);
      });
  }
  const onUserStoppedTyping = (sponID) => {
    // console.log(sponID);
    setSponsorLoading(true);
    axios({
      method: "post",
      url: ApiPaths.checkUsername,
      data: {
        username: sponID,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // console.log("response", response)
        setCheckSponsorExist(response?.data);
        setLoading(false);
        setSponsorLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
        setSponsorLoading(false);
      });
    // console.log("User stopped typing. Do something here.");
  };
  const es6Function = () => {
    FetchData();
    FetchPlanData();
  };

  return (
    <>
      {loading ? <Loader /> : null}

      {/* <button onClick={GetBalance}>Get</button> */}
      <section className="dashboard">
        <h1 className="textHeadingWithMargin">PAID LICENSE</h1>
        <section className="upgradesCardSection">
          <Row className="mt-3" style={{ rowGap: "10px" }}>
            {Array.isArray(planData?.package_data) && planData?.package_data?.map((x, i) => {
              return (
                <Col md="3" className="mb">
                  <UpgradeCard
                    data={x}
                    walletAddress={dashboardData?.profile?.[0]?.wallet_address}
                    refAddress={dashboardData?.profile?.sponsor_wallet_address}
                    username={subsUserId}
                    es6Function={es6Function}
                  />
                </Col>
              );
            })}
          </Row>
        </section>
        {/* <h1 className="textHeadingWithMargin">Fix Packages</h1>
        <section className="upgradesCardSection">
          <Row className="mt-3" style={{ rowGap: "10px" }}>
            {planData?.package_data?.map((x, i) => {
              if (x?.package_type == 'fix')
                return (
                  <Col md="3" className="mb">
                    <UpgradeCard
                      data={x}
                      walletAddress={dashboardData?.profile[0]?.wallet_address}
                      refAddress={dashboardData?.profile?.sponsor_wallet_address}
                      username={subsUserId}
                      es6Function={es6Function}
                    />
                  </Col>
                );
            })}
          </Row>
        </section> */}
      </section>
    </>
  );

  function resetAiSubError() {
    setAiPackageError("");
    setAiUserIdError("");
  }
  function AIDepositFunc() {
    let x = 0;
    resetAiSubError();
    if (!aiUserId.length > 0) {
      setAiUserIdError("UserID cannot be empty");
    } else {
      if (checkSponsorExist?.res == "success") {
        x++;
      }
    }
    if (aiPackage == "1") {
      setAiPackageError("Please select package");
    } else {
      x++;
    }
    if (x === 2) {
      setLoading(true);
      let token = localStorage.getItem("token");
      axios({
        method: "post",
        url: ApiPaths.deposit,
        data: {
          selected_wallet: "fund_wallet",
          tx_username: aiUserId,
          selected_pin: aiPackage,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
        },
      })
        .then(function (response) {
          // console.log("packages", response);
          if (response?.data?.res == "error") {
            if (response?.data?.tokenStatus == "false") {
              toastFailed("Token Expired Please Login");
            } else {
              toastFailed(response?.data?.message);
            }
          } else {
            toastSuccess(response?.data?.message);
            FetchData();
          }
          setLoading(false);
        })
        .catch(function (response) {
          // console.log(response);
          setLoading(false);
        });
    }
  }
  function resetSubError() {
    setSubsPackageError("");
    setSubsUserIdError("");
    setSubsAmountError("");
    setSubsWalletsError("");
  }
};

export default Plans;
