import BUSD_ABI from "../Contracts/BUSD_ABI.json";
import contractABI from "../Contracts/contract_ABI.json";
import tokenABI from "../Contracts/Token_ABI.json";
import tradingABI from '../Contracts/TradingABI.json'
import controllerABI from '../Contracts/controllerABI.json'
// const arr  = {contract:"0x7928986fe9728c35cD49a0240281f03351Ce5672",contractABI:contractABI,BUSD:"0xe37b70Ef457899F0afdFB71CEd2671CFA84ef770",BUSD_ABI:BUSD_ABI}
import { ethers } from 'ethers';
const arr = {
  chainAllowed: 137,
  allowedChainName: 'Polygon Mainnet',
  contract: "0xe4a6c136C357859368fb37233F14B5f8bfc18828",
  contractABI: contractABI,
  BUSD: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  BUSD_ABI: BUSD_ABI,
  contract2: "0xC6cBD0228256a1E215474b34B14A3B6817e9040C",
  tokenABI: tokenABI,
  tradingContract:"0xa9Febc2fBbA032F3A85F8Afee2a3949eD47A7A38",
  tradingABI:tradingABI,
  multiplier: 1e6,
  controller:"0xF473671670AF4701bfE2549AEA9B24cEb02d4a35",
  controllerABI:controllerABI
};

////////////////testnet//////////////////
// const arr = {
//   contract: "0x98F7A9bB657dE1a6E7BaD6655483850c74A878e1",
//   contractABI: contractABI,
//   BUSD: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
//   BUSD_ABI: BUSD_ABI,
//   token: "0xa30C22b19fCd4A7209d9E55e15D890a350c6Ae4F",
//   tokenABI: tokenABI,
// };


export const getEthereum = () => {
  if (window.ethereum) {
    return window.ethereum;
  } else {
    console.warn("No Ethereum provider found. Install MetaMask");
    return null;
  }
};

export const getContract = async () => {
  const ethereum = getEthereum();
  if (ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(arr.controller, arr.controllerABI, signer);
    return contract;
  }
  return null;
};

export const ContractDetails = arr;

// Action creators are generated for each case reducer function

export default ContractDetails;
