import React, { useState } from "react";
import "./Header.css";
import LogoIcon from "./../../Images/logoIcon.png";
import User from "./../../Images/user.png";
import { FiCopy } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { setSidebarDisplay } from "./../../Redux/SideDisplaySlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CopyFromtag from "../../Common/CopyFromtag";
import ReferralComponent from "../ReferralComponent";
const Header = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="header">
        <div className="alertMsg" id="CopiedMsg" style={{ top: "-130px" }}>
          Link Copied!
        </div>
        <div>
          <Link to="/dashboard">
            <img id="logoicon" src={LogoIcon} alt="logo.png" />
          </Link>
          {/* <div className="headerLogoLink headerLinkHeader">
            <div
              className="headerLinkDiv"
            >
              <div className="linktext">
                <p>your referral link</p>
                <h2 id="headerLink1">{props.link}</h2>
              </div>
              <i
                onClick={() => CopyFromtag("headerLink1")}>
                <FiCopy />
              </i>
              <ReferralComponent link={props.link} />

            </div>
          </div> */}
        </div>
        <div className="headerProfileColorDiv">

          <i className="userIcon">
            <FaUserCircle />
          </i>
          <div
            className="headerProfile"
          >
            <img src={User} alt="" />
            <h5>{props.username}</h5>
          </div>
          <i
            className="menuIcon"
            onClick={() => dispatch(setSidebarDisplay("block"))}
          >
            <FiMenu />
          </i>
        </div>
      </div>
    </>
  );
};

export default Header;
